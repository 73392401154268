<template>
  <div class="Article">
    <div class="banner">
      <img width="100%" src="../assets/banner5.png" alt="" />
      <!-- <div class="title">
        <div class="h1">Recommendation Column</div>
      </div> -->
    </div>
    <!-- <div class="hearder"></div> -->
    <div class="nav">
      <h1>{{ data.subject }}</h1>
      <div class="time">{{ data.datecreated.substring(0, 10) }}</div>
      <div class="text" v-html="data.description"></div>
    </div>
  </div>
</template>
<script>
import http from "../js/http";
export default {
  data() {
    return {
      data: {
        subject: "",
        datecreated: "",
        description: "",
      },
    };
  },
  mounted() {
    let id = this.$route.query.id;
    /* console.log(id); */
    http({
      url: `/hkt/hkt/Information/news_details?id=${id}&lang=en`,
    }).then((res) => {
      this.data = res.data.data;
      /* console.log(this.data); */
      if (
        this.data.description.indexOf(
          "https://www.trusthongkong.com/hktcrm"
        ) === -1
      ) {
        this.data.description = this.data.description.replace(
          /\/hktcrm/g,
          "https://www.trusthongkong.com/hktcrm"
        );
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.hearder {
  padding-bottom: 150px;
  background: #000;
}
.nav {
  width: 60vw;
  margin: 50px auto 100px;
  text-align: left;
  /* margin-bottom: 100px; */
}
#app {
  .pc {
    .HeardPc {
      .heard {
        display: none;
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1500px) {
  .nav {
    width: 80vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title .h1 {
    font-size: 36px;
  }
  .icon {
    font-size: 16px;
  }
  .nav {
    width: 80vw;
    margin-top: 75px;
    margin-bottom: 100px;
    .content {
      padding-bottom: 30px;
      p {
        font-size: 20px;
        margin: 0px;
      }
      .time {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .banner {
    margin-top: 64px;
  }
  .nav {
    width: 90vw;
    margin: 30px auto 20px;
    h1 {
      font-size: 20px;
    }
    .time {
      margin-bottom: 5px;
    }
    .text {
      ::v-deep p {
        line-height: 3em !important;
      }
      ::v-deep img {
        width: 100% !important;
      }
    }
  }
}
</style>
